<template>
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <!-- <div class="top-search">
        <top-filter :config="{experiment: true}" :hasSearch="true" @handleSearchParams="handleSearch"></top-filter>
      </div>-->
      <div class="article-list">
        <div class="tabs">
          <div
            :class="{'tab-item': true, 'active': currentTab == item.key}"
            @click="handleTabClick(item)"
            v-for="(item, t) in tabList"
            :key="t"
          >{{item.name}}</div>
          <div class="send" @click="navToSend" v-if="currentTab == 2">发布需求</div>
        </div>
        <div class="item" v-for="(t, i) in dataList" :key="i">
          <img :src="t.pictureUrl" class="img" />
          <div class="text">
            <strong class="h2">{{t.instrumentName}}</strong>
            <div>
              <span class="label">发起时间：</span>
              <span>{{t.initiateTime}}</span>
              <span class="label">攻关难点：</span>
              <span>{{t.difficultPoint}}</span>
            </div>
            <div>{{ t.projectSignificance }}</div>
            <div>
              <span class="label">发起单位：</span>
              <span>{{t.initiateUnit}}</span>
            </div>
          </div>
          <div class="info" @click="navTo(t)">查看详情</div>
        </div>
        <div class="footer">
          <div class="left">共 {{total}} 条</div>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page.pageNum"
            layout="prev, pager, next"
            :page-size="3"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from '../../components/topFilter.vue'
export default {
  name: 'market',
  components: {
    TopFilter
  },
  data() {
    return {
      current: 0,
      keyword: "",
      dataList: [],
      total: null,
      page: {
        pageNum: 1,
        pageSize: 3
      },
      queryInfo: {
        keywords: null,
        type: 1
      },
      tabList: [
        {
          name: '联合开发',
          key: 1
        },
        {
          name: '委托开发',
          key: 2
        }
      ],
      currentTab: 1
    }
  },
  computed: {
  },
  created() {
    this.getList()

  },
  mounted() {

  },
  methods: {
    handleSearch(params) {
      // this.queryInfo.type = params.experiment
      this.queryInfo.keywords = params.keywords
      this.getList();
    },
    handleTabClick(item) {
      this.currentTab = item.key
      this.queryInfo.type = item.key
      this.getList();
    },
    navTo(t) {
      this.$router.push({ path: '/sharedInstrument/instrumentDetail', query: { id: t.id, title: '仪器研发', type: 4 } })
    },
    navToSend(t) {
      this.$router.push({ path: '/sharedInstrument/send' })
    },
    getList() {
      let params = Object.assign(this.page, this.queryInfo)
      this.$api.share.rd(params).then((res) => {
        let data = res.data;
        this.dataList = data.rows
        this.total = data.total
      })
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.paper {
  padding: 20px 100px;
}
.top-search {
  background: #fff;
  padding: 20px 40px 40px;
  .form-item {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    .search {
      width: 300px;
      margin-left: 30px;
    }
  }
}
.article-list {
  background: #fff;
  margin-top: 20px;
  padding: 30px;
  .tabs {
    border-bottom: 1px solid #efefef;
    display: flex;
    position: relative;
    .tab-item {
      padding: 14px 20px;
      cursor: pointer;
    }
    .tab-item.active {
      color: #0ff;
      border-bottom: 2px solid #0ff;
      font-weight: 700;
    }
    .send {
      position: absolute;
      right: 0;
      top: 8px;
      background: #0ff;
      color: #fff;
      padding: 5px 20px;
      cursor: pointer;
    }
  }
  .item {
    display: flex;
    border-bottom: 1px solid #efefef;
    padding: 20px;
    align-items: center;
    .img {
      width: 160px;
      height: auto;
    }
    .h2 {
      color: #0ff;
    }
    .text {
      flex: 1;
      margin: 0 40px;
      line-height: 2;
      span {
        padding-right: 15px;
      }
      .label {
        color: #999;
      }
    }
    .info {
      display: inline-block;
      background-color: #0046c0;
      color: #fff;
      padding: 8px 15px;
      border-radius: 6px;
      margin: 10px 20px 20px;
      cursor: pointer;
      height: 24px;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .footer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #0046c0;
    }
  }
}
</style>
